// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56,128,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #086788;
  --ion-color-secondary-rgb: 8,103,136;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #075b78;
  --ion-color-secondary-tint: #217694;

  /** tertiary **/
  --ion-color-tertiary: #13293D;
  --ion-color-tertiary-rgb: 19,41,61;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #112436;
  --ion-color-tertiary-tint: #2b3e50;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-contrast: #fff;
  --ion-color-google: #dd4b39;
  --ion-color-google-contrast: #fff;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}


.error-message{
  padding-left: 15px;
  padding-top: 5px;
  height: 10px;
}

.tab-selected{
  background: var(  --ion-color-primary) !important;
  color: white !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  //border-bottom-right-radius: 10px;
  //border-bottom-left-radius: 10px;
}

.alertCancel{
  .alert-sub-title{
    font-size: small;
    color: brown;
    position: relative;
    top: 10px;
  }
  .alert-input-group{
    padding-bottom: 10px;
    padding-top: 10px;
  }
 }

.modal-alert-small {
	--height: 250px !important;
  --width: 300px !important;
  .modal-wrapper{
    border-radius: 5px;
  }
}

.modal-alert-medium {
	--height: 450px !important;
  --width: 300px !important;
  .modal-wrapper{
    border-radius: 5px;
  }
}

.modal-alert-large {
	--height: 610px !important;
  --width: 300px !important;
  .modal-wrapper{
    border-radius: 5px;
  }
}


.modalImg {
	--background: transparent; 
  .modal-wrapper {
    height: 100%;
    width: 100%;
 }
}

.alert-radio-label.sc-ion-alert-md {
  white-space: pre-line;
}

.sc-ion-select-popover.sc-ion-label-md-h{
  white-space: pre-line !important;
}

.button-inner {
  flex-flow: column;
}

.list-md{
  padding: 0px !important;
}

.my-text-wrap{
  white-space: pre-wrap !important;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}

.alert-tappable.sc-ion-alert-md{
  height: 55px;
}