// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.error-message {
  height: 35px;
  font-size: 0.75em !important;
  color: var(--ion-color-danger);
  border-bottom: 0 !important;
  &--small {
    font-size: 0.50em !important;
  }
}

ion-item.invalid {
  --border-color: var(--ion-color-danger) !important;
}

ion-datetime.invalid {
  ion-ripple-effect {
    border-bottom: 1px solid var(--ion-color-danger) !important;
  }
}

ion-spinner {
  display: block;
  margin: auto;
}

.error-toast {
  z-index: 99999;
  text-align: center;
}

.success-toast {
  // Hack para que se vean cuando hay un modal abierto.
  z-index: 99999;
  text-align: center;
}

.warning-toast {
  z-index: 99999;
  text-align: center;
}


.absolute--top--right {
  position: absolute;
  top: 15px;
  right: 15px;
}

.fab-button-secondary--bordered {
  --border-color: var(--ion-color-secondary);
  --border-width: 2px;
  --border-style: solid;
}

.image-placeholder {
  background-color: lightgray;
  min-height: 231px;
  width: 100%;
}

.no-data-item {
  align-items: center;
}


/* loading app_root */ 
.spin{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ion-spinner {
    width: 28px;
    height: 28px;
    stroke: #444;
    fill: #222;
    transform: scale(2);
  }  
}
/* loading */

.button-footer-cuadrado{
  border-radius: 2px;
  margin-left: 0;
  margin-right: 8px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  background-color: var(--ion-color-primary, #3880ff) !important;
  color: #fff !important;
  font-weight: 500;
  text-align: end;
  text-transform: uppercase;
  overflow: hidden;
}


ion-item.selw75 {
  ion-label {
    min-width: 25%;
  }
  ion-select {
    min-width: 75%;
  }
}

.buttons-footer{
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
  .button-footer{
    @extend .button-footer-cuadrado;
  }

}

.alert-button{
  @extend .button-footer-cuadrado;
}

.custom-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border: none; /* Eliminar el borde */
  box-shadow: none; /* Eliminar la sombra */
  background-color: transparent; /* Cambiar el color de fondo si es necesario */
  --border-width: 0; /* Eliminar el borde de Ionic */
  --ion-border-color: transparent; /* Asegúrate de que el color del borde sea transparente */
}


.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
